import React, { useCallback } from "react";
import { navigate } from "gatsby";
import Button from "@common/Button/Button";
import { MAIN_BUTTON, EMPTY_VACANCY_IDS } from "@constants";
import "./ErrorPage.scss";

const ErrorPage = ({
    title,
    firstDescription,
    secondDescription,
    thirdDescription,
    buttonText,
    navigateTo,
}) => {
    const handleClick = useCallback(() => {
        navigate(navigateTo, {
            state: {
                vacancyId: EMPTY_VACANCY_IDS[0],
            },
        });
    }, [navigate]);

    return (
        <div className="error-page">
            <div className="error-page__heading-container">
                <h1 className="error-page__heading">{title}</h1>
                <p className="error-page__description">
                    {firstDescription}
                    <br />
                    {secondDescription}
                    {thirdDescription && (
                        <>
                            <br />
                            {thirdDescription}
                        </>
                    )}
                </p>
            </div>
            <div className="error-page__btn-container">
                <Button
                    text={buttonText}
                    type={MAIN_BUTTON}
                    handleClick={handleClick}
                />
            </div>
        </div>
    );
};
export default ErrorPage;
